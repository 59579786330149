<template>
  <div>
    <loader />
  </div>
</template>

<script>
  import Loader from '@/components/Loader.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'Impersonate',
    components: { Loader },
    async created() {
      if (this.$route.query.token) {
        localStorage[this.$auth.authTokenKey] = this.$route.query.token
        await this.$auth.refresh()
      }
      if (this.$auth.isAuth) {
        this.$router.push({ name: routeNames.MAIN })
      }
    }
  }
</script>
